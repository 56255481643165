import Image from 'next/image'
import ReactMarkdown from 'react-markdown'
import { useMediaQuery } from 'react-responsive'

import styles from './media-card.module.scss'
import { constants } from '@/lib/constants'

type MediaCardProps = {
  tag: string
  title: string
  description: string
  image: string
}

export const MediaCard = ({ tag, title, description, image }: MediaCardProps) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })

  return (
    <div className={styles.card}>
      <div className={styles.text}>
        <p className={styles.tag}>{tag}</p>
        <h3>{title}</h3>
        <div className={styles.description}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
      <div className={styles.image}>
        <Image
          src={`${constants.imageHost.assets}/homepage/${isMobile ? 'mobile-' : ''}${image}`}
          alt={title}
          layout="fill"
        />
      </div>
    </div>
  )
}
